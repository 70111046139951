import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Collapse, Alert } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'gatsby'
import InputBox from '../Form/InputBox'
import DownloadHistory from './DownloadHistory'

import { checkLink, linksArr, getLinkType } from '../../helpers'
import { postData } from '../../reducers/app'
import { getDownloadLink } from '../../reducers/generator'
import { saveLinks as saveForLater } from '../../reducers/savedlinks'
import { PREMIUM } from '../../data/constants'
import { SITE_LINKS } from '../../data/apis'

import { isPremiumUser } from '../../selectors/user'

import styles from './index.module.scss'
import GenericButton from './GenericButton'

const Generator = ({
  leeching,
  savedlinks: { saved, saving, savedMsg, error: errorMsg },
  toggleCollapse,
  openStates,
}) => {
  const [state, setState] = useState({
    type: 'link',
    links: '',
    error: '',
  })
  const [openMsg, setOpenMsg] = useState(false)
  const dispatch = useDispatch()
  const isPremium = useSelector(isPremiumUser)

  const handleChange = e => {
    const {
      target: { value },
    } = e
    setState({
      ...state,
      type: getLinkType(value),
      links: value,
      error: checkLink(value) ? 'Invalid Link' : '',
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const { links, type } = state
    const getHTTPLinks = linksArr(links, type)
    if (type === 'link') {
      if (openMsg) setOpenMsg(false)
      dispatch(getDownloadLink(getHTTPLinks))
    }
    if (type === 'site' || type === 'folder') {
      if (isPremium) dispatch(postData(SITE_LINKS, { url: getHTTPLinks[0] }))
      else setOpenMsg(true)
    }
    setState({
      ...state,
      type: 'link',
      error: '',
      links: '',
    })
  }

  const handleReset = e => {
    e.preventDefault()
    dispatch(getDownloadLink([]))
    toggleCollapse('reset')(e)
    setOpenMsg(false)
    setState({
      ...state,
      type: 'link',
      error: '',
      links: '',
    })
  }

  const saveLinks = e => {
    e.preventDefault()
    const { links } = state
    const getHTTPLinks = {
      links: linksArr(links) || [],
    }
    dispatch(saveForLater(getHTTPLinks))
  }

  return (
    <>
      <section className={styles.block}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 text-right p-0">
              <InputBox
                label=""
                name="generator"
                type="textarea"
                placeholder={
                  'Enter links..(Multiple links 1 link per line. Max 6 links)\nExample:\nhttp://uploaded.net/file/043f64pg\nhttp://wushare.com/file/dT2sULrj\n....'
                }
                value={state.links}
                onChangeInput={handleChange}
                error={state.error}
                rows="5"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 mb-1">
              <GenericButton
                type={state.type}
                isDisabled={!!state.error || leeching || !state.links || saving}
              />
              <Button
                className="btn-sm ml-2"
                color={leeching ? 'warning' : 'info'}
                onClick={handleReset}
              >
                {leeching ? 'Stop' : 'Reset'}
              </Button>
            </div>
            {isPremium && (
              <div className="col-sm-6 text-right">
                <Button
                  className="btn-sm mr-2"
                  color={saving ? 'secondary' : 'primary'}
                  onClick={saveLinks}
                  disabled={!!state.error || leeching || !state.links || saving}
                  title="Saved links"
                >
                  {saving && !saved ? 'Saving..' : 'Save Links'}
                </Button>
                <Button
                  className="btn-sm"
                  color="danger"
                  onClick={toggleCollapse('history')}
                  disabled={leeching}
                  title="Download History"
                >
                  <i className="fas fa-history mr-0" />
                </Button>
              </div>
            )}
          </div>
          {errorMsg && savedMsg === '' && !saving && !saved && (
            <div className="col-lg-6 float-right">
              <i
                className="fas fa-exclamation-circle"
                style={{ color: '#FF0000' }}
              />
              {errorMsg}
            </div>
          )}
          {savedMsg !== '' && !saving && saved && (
            <div className="col-lg-6 float-right">
              <i className="far fa-check-circle" style={{ color: '#0000ff' }} />
              {savedMsg || 'Added to your profile'}
            </div>
          )}
        </form>
        <DownloadHistory isOpen={openStates.isHistoryOpen} />
        <Collapse isOpen={openMsg && !isPremium}>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
            <Alert
              color="danger"
              isOpen={openMsg}
              toggle={() => setOpenMsg(false)}
            >
              <i className="fas fa-info-circle" />
              Links fetch currently only available for Premium users.{' '}
              <Button size="sm" color="danger">
                <Link to={PREMIUM} className="text-white">
                  Buy Premium
                </Link>
              </Button>
            </Alert>
          </div>
        </Collapse>
      </section>
    </>
  )
}

Generator.propTypes = {
  savedlinks: PropTypes.shape({
    saving: PropTypes.bool.isRequired,
    saved: PropTypes.bool.isRequired,
    savedMsg: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
  }).isRequired,
  openStates: PropTypes.object.isRequired,
  leeching: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
}

export default Generator
