import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

const FOLDER_LINK = 'folder'
const SITE_LINK = 'site'

const BtnTypes = type => {
  switch (type) {
    case FOLDER_LINK:
      return {
        class: 'warning',
        icon: 'fas fa-link',
        name: 'Get Folder Links',
      }
    case SITE_LINK:
      return {
        class: 'warning',
        icon: 'fas fa-search',
        name: 'Find Links',
      }
    default:
      return {
        class: 'success',
        icon: 'fas fa-download',
        name: 'Download Links',
      }
  }
}

const GenericButton = ({ type, isDisabled }) => {
  const [btnConfig, setBtnConfig] = useState(BtnTypes(''))
  useEffect(() => {
    setBtnConfig(BtnTypes(type))
  }, [type])
  return (
    <Button
      size="sm"
      color={btnConfig.class}
      disabled={isDisabled}
      title={btnConfig.name}
    >
      <i className={`${btnConfig.icon} mr-1`} />
      {btnConfig.name}
    </Button>
  )
}

GenericButton.propTypes = {
  type: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default GenericButton
