import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { trimText } from '../../helpers'
import Videojs from './Video'

const HistoryTable = ({ link, filename, dlink }) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    return () => {
      setIsOpen(false)
    }
  }, [])
  const handlePopup = () => {
    setIsOpen(prev => !prev)
  }
  return (
    <tr>
      <td>{link}</td>
      <td>{trimText(filename, 30, 10)}</td>
      <td>
        {/.mp4*$/i.test(filename) ? (
          <Button className="btn-sm" color="success" onClick={handlePopup}>
            <i className="fa fa-play mr-0" aria-hidden="true" />
          </Button>
        ) : (
          <Button className="btn-sm" color="secondary" disabled>
            <i className="fa fa-play mr-0" aria-hidden="true" />
          </Button>
        )}
        {isOpen && (
          <Videojs
            isOpen={isOpen}
            handlePopup={handlePopup}
            filename={filename}
            dlink={dlink}
          />
        )}
        <a target="_blank" rel="noopener noreferrer" href={dlink}>
          <Button className="btn-sm" color="danger">
            <i className="fa fa-download mr-0" aria-hidden="true" />
          </Button>
        </a>
      </td>
    </tr>
  )
}

HistoryTable.propTypes = {
  filename: PropTypes.string.isRequired,
  dlink: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default HistoryTable
