import { SITE_REGEX, FOLDER_REGEX } from '../data/regex'

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Bytes'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`
}

export const isKeyExists = (obj, nesting) => {
  const value = nesting.split('.').reduce((a, b) => (a || {})[b], obj)
  if (!value) return false
  return value
}

export const trimText = (str, till, end) =>
  str.length <= till
    ? str
    : `${str.substr(0, Number(till))}...${str.substr(-Number(end))}`

/* eslint-disable */
export const getTimeLeft = tm => {
  const milliSec = Math.round((Number(tm) - Date.now()) / 1000)
  const days = Math.floor(milliSec / (60 * 60) / 24)
  const hours = Math.floor((milliSec / (60 * 60)) % 24)
  const minutes = Math.floor((milliSec / 60) % 60)
  const seconds = Math.floor(milliSec % 60)
  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) return false
  if (days === 0 && hours === 0 && minutes === 0)
    return `${seconds > 9 ? '' : '0'}${seconds} sec`
  if (hours === 0)
    return `${minutes > 9 ? '' : '0'}${minutes} min ${
      seconds > 9 ? '' : '0'
    }${seconds} sec`
  if (days === 0)
    return `${hours > 9 ? '' : '0'}${hours} hr ${
      minutes > 9 ? '' : '0'
    }${minutes} min ${seconds > 9 ? '' : '0'}${seconds} sec`
  return `${days > 9 ? '' : '0'}${days} day${days > 1 ? 's' : ''} ${
    hours > 9 ? '' : '0'
  }${hours} hr ${minutes > 9 ? '' : '0'}${minutes} min ${
    seconds > 9 ? '' : '0'
  }${seconds} sec`
}

export const debounce = (callBack, delay) => {
  let timerId = null
  return () => {
    if (timerId) {
      clearTimeout(timerId)
      timerId = null
    }
    timerId = setTimeout(callBack, delay)
  }
}

export const removeTrailingSlash = str => {
  if (str && str.length >= 2) {
    if (str.match(/^\/user\/(.*)(\/|#)/)) {
      const [, page] = str.match(/^\/user\/(.*)(\/|#)/)
      return `/user/${page}`
    }
    return str.replace(/\/$/, '')
  }
  return str
}

export const getParamFromURL = (str, param) => {
  try {
    const urlParams = new URLSearchParams(str)
    if (urlParams.has(param)) {
      return urlParams.get(param)
    }
    return ''
  } catch (err) {
    console.log(err)
    return ''
  }
}

export const toCamelCase = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const linksArr = (links, type = 'link') => {
  const httpLinks = links.split(/\r|\n/).map(link => link.replace(',', ''))
  const getHTTPLinks = [...new Set(httpLinks)]
    .filter(x => x.match(/^(http|https):\/\//))
    .slice(0, 6) /* max 6 links */
    .map(y => y.trim())
    .filter(lnk => getLinkType(lnk) === type)
  return getHTTPLinks
}

export const checkLink = link => {
  if (link.match(/^(http|https):\/\//)) return false
  return true
}

export const getLinkType = link => {
  const isSiteLink = SITE_REGEX.some(reg => reg.test(link))
  if (isSiteLink) {
    return 'site'
  }
  const isFolderLink = FOLDER_REGEX.some(reg => reg.test(link))
  if (isFolderLink) {
    return 'folder'
  }
  return 'link'
}
