import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { getTimeLeft } from '../../helpers'

const Timer = ({ tm, children, timeEndCallback }) => {
  const [time, setTime] = useState('')

  let timer

  const tick = ms => () => {
    const timeLeft = getTimeLeft(ms)
    if (timeLeft === false) {
      clearInterval(timer)
      timeEndCallback()
      return setTime('')
    }
    setTime(timeLeft)
  }

  useEffect(() => {
    timer = setInterval(tick(tm), 1000)
    if (timer === '' && typeof timeEndCallback === 'function') timeEndCallback()
    return () => {
      clearInterval(timer)
    }
  }, [])

  return <>{children(time)}</>
}

Timer.defaultProps = {
  timeEndCallback: () => {},
}

Timer.propTypes = {
  tm: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
  timeEndCallback: PropTypes.func,
}

export default Timer
