import React, { useState } from 'react'

import UserInfo from '../UserInfo'

import styles from './index.module.scss'

const tableSettings = {
  responsive: true,
}

const UserCard = () => {
  const [username, setUsername] = useState('')
  return (
    <div className="row">
      <div className={`${styles.status} col-lg-12 col-md-12 col-sm-12 col-12`}>
        <h5 className="mt-0">
          <i className="fas fa-user-circle"></i>
          Welcome {username.toUpperCase()}
        </h5>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <UserInfo
          showUsername={false}
          tableSettings={tableSettings}
          setUsername={setUsername}
        />
      </div>
    </div>
  )
}

export default UserCard
