/* eslint-disable jsx-a11y/media-has-caption */

import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import PropTypes from 'prop-types'
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  BigPlayButton,
} from 'video-react'
import 'video-react/dist/video-react.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import styles from './index.module.scss'

const videoJsOptions = dlink => {
  return {
    autoPlay: true,
    fluid: false,
    width: 798,
    height: 450,
    src: `${dlink}?stream=true`,
  }
}

const VideoPlayer = ({ isOpen, handlePopup, filename, dlink }) => {
  const [isDarkMode, setDarkMode] = useState(false)
  const playerRef = useRef()

  const handleStateChange = state => {
    if (isDarkMode) {
      setDarkMode(!state.ended)
    }
  }

  useEffect(() => {
    // subscribe state change
    if (playerRef && playerRef.subscribeToStateChange) {
      playerRef.subscribeToStateChange(handleStateChange)
    }
  }, [playerRef])

  const handleDarkMode = () => {
    setDarkMode(prev => !prev)
  }
  return (
    isOpen && (
      <Modal isOpen={isOpen} toggle={handlePopup} className="modal-lg">
        <ModalBody>
          <div
            className={`${styles.lightContainer} ${
              isDarkMode ? styles.night : ''
            }`}
          >
            <div className="row">
              <div className="col-8">
                <b>{filename}</b>
              </div>
              <div className="col-4 text-right">
                <Button
                  color={`${isDarkMode ? 'light' : 'dark'}`}
                  className={`${styles.lightoffVisible} btn-sm mr-2`}
                  onClick={handleDarkMode}
                >
                  <i className="far fa-lightbulb" />
                </Button>
                <a target="_blank" rel="noopener noreferrer" href={dlink}>
                  <Button color="primary" className="btn-sm mr-2">
                    <i className="fa fa-download mr-1" aria-hidden="true" />
                    Download
                  </Button>
                </a>
                <i
                  className="fas fa-times"
                  onClick={handlePopup}
                  role="presentation"
                />
              </div>
            </div>
            <div className={styles.nightHolder} />
            <div className={`${styles.lightoffVisible}`}>
              <Player ref={playerRef} {...videoJsOptions(dlink)}>
                <BigPlayButton position="center" />
                <ControlBar autoHide>
                  <PlaybackRateMenuButton
                    order={7}
                    rates={[5, 2.5, 2, 1, 0.5]}
                  />
                </ControlBar>
              </Player>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  )
}

VideoPlayer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handlePopup: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  dlink: PropTypes.string.isRequired,
}

export default VideoPlayer
