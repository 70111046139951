import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Badge, ListGroup, ListGroupItem } from 'reactstrap'

import FileInfo from './FileInfo'
import ErrorHandler from './ErrorHandler'

import { retryDownloadLink } from '../../reducers/generator'
import { isPremiumUser } from '../../selectors/user'

import styles from './index.module.scss'

const isDone = (resolved, index) => resolved.includes(index)

const DownloadTable = () => {
  const {
    links,
    results,
    resolved,
    leeching,
    currentIndex,
    retryCount,
  } = useSelector(state => state.generator)
  const dispatch = useDispatch()
  const isPremium = useSelector(isPremiumUser)

  const retryLink = (link, index) => () => {
    dispatch(retryDownloadLink(link, index))
  }

  return (
    <section className={styles.block}>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h5 className="mt-0 mb-2 text-danger">
            <i className="fa fa-solid fa-link mr-1"></i>Download Links
          </h5>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <ListGroup>
            {links.map((link, index) => (
              <ListGroupItem key={String(index)}>
                {currentIndex === index && (
                  <span>
                    <i className={`fas fa-sync-alt ${styles.faSpin}`} />
                    {link}
                  </span>
                )}
                {/* eslint-disable */}
                {currentIndex !== index &&
                  isDone(resolved, index) &&
                  !results[index].error && (
                    <FileInfo
                      link={link}
                      filename={results[index].filename}
                      filesize={results[index].filesize}
                      dlink={results[index].dlink}
                      serverId={results[index].serverId}
                      isPremium={isPremium}
                    />
                  )}
                {currentIndex !== index &&
                  isDone(resolved, index) &&
                  results[index].error && (
                    <ErrorHandler
                      className="mt-1"
                      link={link}
                      msg={results[index].msg}
                      leeching={leeching}
                      retryCount={retryCount}
                      onClick={retryLink(link, index)}
                    />
                  )}
                {currentIndex !== index && !isDone(resolved, index) && (
                  <span className={styles.trimText}>
                    <Badge color="info" className={styles.waitingBadge}>
                      waiting...
                    </Badge>
                    {link}
                  </span>
                )}
              </ListGroupItem>
            ))}
          </ListGroup>
          {/* eslint-enable */}
        </div>
      </div>
    </section>
  )
}

export default DownloadTable
