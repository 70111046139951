import React, { useState, useEffect } from 'react'
import {
  ListGroup,
  ListGroupItem,
  Input,
  FormGroup,
  Label,
  Button,
  Alert,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { getDownloadLink } from '../../reducers/generator'
import { resetState } from '../../reducers/app'

import styles from './index.module.scss'
import { toCamelCase } from '../../helpers'

/* TODO: Handle Error state */
/* TODO: Check feasibility for folder links also */

const FetchedLinks = () => {
  const [host, setHost] = useState('')
  const [hostLinks, setHostLinks] = useState([])
  const [selectedLinks, setSelectedLinks] = useState([])

  const dispatch = useDispatch()

  const handleSelected = selected => () => {
    if (!selectedLinks.includes(selected))
      setSelectedLinks([...selectedLinks, selected])
    else setSelectedLinks(selectedLinks.filter(lnk => lnk !== selected))
  }

  const handleSelectAll = () => {
    if (hostLinks.length === selectedLinks.length) setSelectedLinks([])
    else setSelectedLinks(hostLinks.map(parts => parts.link))
  }

  const handleClose = e => {
    e.preventDefault()
    dispatch(resetState('fetchedLinks'))
  }

  const handleDownloads = e => {
    e.preventDefault()
    dispatch(getDownloadLink(selectedLinks))
  }

  const { loading, data, loaded } = useSelector(({ app }) => app.fetchedLinks)
  const {
    result: { link, linksData = [], preferred = '', error, status = 'offline' },
  } = data

  const handleFileHost = e => {
    setHost(e.target.value)
  }

  useEffect(() => {
    if (loading && host === '') {
      setHost('')
    }
    if (host && !loading) {
      setSelectedLinks([])
      setHostLinks(linksData.filter(ele => ele.host === host)[0].links)
    }
  }, [host, loading])

  useEffect(() => {
    if (linksData.length > 0) {
      if (preferred) setHost(preferred)
      else setHost(linksData[0].host)
    }
  }, [linksData])

  return (
    <section className={styles.block}>
      <div className="container-fluid px-0">
        {!loading && loaded && error && (
          <Alert color="danger" toggle={handleClose}>
            {error}
          </Alert>
        )}
        {!loading && loaded && status !== 'online' && (
          <Alert color="danger" toggle={handleClose}>
            {data.result.msg}
          </Alert>
        )}
        {loading && !loaded && (
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <FormGroup tag="fieldset">
                <div className="shine lines"></div>
                <FormGroup check>
                  <Label check>
                    <div className="shine lines"></div>
                    <div className="shine lines"></div>
                  </Label>
                </FormGroup>
              </FormGroup>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-10 col-10">
              <div
                className="d-flex flex-column"
                style={{ marginLeft: '8rem' }}
              >
                <div
                  className="shine w-100 lines"
                  style={{ height: '22px' }}
                ></div>
                <div
                  className="shine w-100 lines"
                  style={{ height: '22px' }}
                ></div>
                <div
                  className="shine w-100 lines"
                  style={{ height: '22px' }}
                ></div>
                <div
                  className="shine w-100 lines"
                  style={{ height: '22px' }}
                ></div>
                <div
                  className="shine w-100 lines"
                  style={{ height: '22px' }}
                ></div>
                <div
                  className="shine w-100 lines"
                  style={{ height: '22px' }}
                ></div>
              </div>
            </div>
          </div>
        )}
        {!loading && loaded && linksData.length > 0 && (
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <FormGroup tag="fieldset">
                <Label className="font-weight-bold text-danger">
                  Select Filehost
                </Label>
                {linksData.map(ele => (
                  <FormGroup check className="py-2" key={ele.host}>
                    <Label check>
                      <Input
                        type="radio"
                        name="host"
                        onChange={handleFileHost}
                        value={ele.host}
                        checked={ele.host === host}
                      />{' '}
                      {toCamelCase(ele.host)}
                    </Label>
                  </FormGroup>
                ))}
              </FormGroup>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-10 col-10">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3 d-flex">
                <div className="w-100 text-truncate">
                  <i className="fas fa-link text-primary"></i>
                  {link}
                </div>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <span aria-hidden="true" className={styles.closeBtn}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="mb-1 d-flex justify-content-between">
                <div className="ml-4">
                  <h5 className="d-flex align-items-center">
                    <Label check>
                      <Input
                        title="Select all links"
                        type="checkbox"
                        className="mt-0"
                        onChange={handleSelectAll}
                        checked={hostLinks.length === selectedLinks.length}
                      />
                      <i className={`hosts_${host.replace('.', '_')} mr-1`}></i>
                      {toCamelCase(host)}
                    </Label>
                  </h5>
                </div>
                <div>
                  <Button
                    color="success"
                    size="sm"
                    onClick={handleDownloads}
                    disabled={selectedLinks.length === 0}
                  >
                    <i className="fas fa-download mr-1" />
                    Download{' '}
                    {selectedLinks.length > 0 &&
                      `${selectedLinks.length} links`}
                  </Button>
                </div>
              </div>
              <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                <ListGroup>
                  {hostLinks.map(ele => (
                    <ListGroupItem key={ele.fname}>
                      <div className="d-flex justify-content-between">
                        <div className="ml-2 w-75">
                          <Label check>
                            <Input
                              type="checkbox"
                              className="pl-2"
                              checked={selectedLinks.includes(ele.link)}
                              onChange={handleSelected(ele.link)}
                            />
                            {ele.fname !== '' ? ele.fname : ele.link}
                          </Label>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default FetchedLinks
