import React from 'react'

import Downloader from '../../components/Downloader'
import PrivateRoute from '../../components/PrivateRoute'
import SEO from '../../components/SEO'

const DownloaderPage = () => {
  return (
    <>
      <SEO page="downloader" />
      <PrivateRoute component={Downloader} />
    </>
  )
}

export default DownloaderPage
