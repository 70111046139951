import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Button } from 'reactstrap'
import styles from './index.module.scss'

const ErrorHandler = ({ link, msg, onClick, leeching, retryCount }) => (
  <div className={`${styles.downloadLinks} ${styles.trimText}`}>
    {link}
    <br />
    {retryCount <= 5 ? (
      <>
        <Badge color="danger" className={styles.fileInfo}>
          {msg}
        </Badge>
        {((msg && msg.indexOf('Error while getting download link') >= 0) ||
          msg.indexOf('Link checker service error') >= 0) && (
          <Button
            disabled={leeching}
            color="warning"
            onClick={onClick}
            className={`${styles.badgeCustom} text-right`}
            size="sm"
          >
            Retry
          </Button>
        )}
      </>
    ) : (
      <Badge color="warning" className={styles.fileInfo}>
        Sorry! Your Max Retries Exceeded. Please re-post the links after
        sometime.
      </Badge>
    )}
  </div>
)

ErrorHandler.propTypes = {
  link: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  leeching: PropTypes.bool.isRequired,
  retryCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ErrorHandler
