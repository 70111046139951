import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Button, Alert, Spinner } from 'reactstrap'
import CheckBox from '../Form/CheckBox'
import InputBox from '../Form/InputBox'
import { getDownloadLink } from '../../reducers/generator'
import styles from './index.module.scss'
import { postData } from '../../reducers/app'
import { FOLDER_LINKS } from '../../data/apis'

const FolderLinks = () => {
  const [selectedLinks, setSelectedLinks] = useState([])
  const [folderLinkError, setFolderLinkError] = useState('')
  const [folderlink, setFolderLink] = useState('')
  const folderData = useSelector(({ app }) => app.folderLinks)
  const dispatch = useDispatch()
  const {
    loading,
    loaded,
    data: {
      result: { status, folderLinks = [], msg },
    },
  } = folderData

  const getAllLinks = e => {
    e.preventDefault()
    if (!folderlink) setFolderLinkError('Please enter folder link.')
    if (folderlink && !folderLinkError)
      dispatch(postData(FOLDER_LINKS, { url: folderlink }))
  }

  const handleChange = e => {
    const { value } = e.target
    if (value && !value.match(/prefiles.com|rapidgator.net|rg.to/)) {
      setFolderLinkError(
        'Only prefiles and rapidgator folder links supported at the moment'
      )
    } else if (
      value &&
      !value.match(
        /^https?:\/\/((prefiles.com\/folder\/)|(rapidgator.net\/folder\/)|(rg.to\/folder\/)|(prefiles.com\/\?usr_id))/
      )
    ) {
      setFolderLinkError('Invalid folder link')
    } else setFolderLinkError('')
    setFolderLink(value)
  }

  const handleSelect = selectedLink => () => {
    if (!selectedLinks.includes(selectedLink))
      setSelectedLinks([...selectedLinks, selectedLink])
    else {
      setSelectedLinks(
        selectedLinks.filter(eachLink => eachLink !== selectedLink)
      )
    }
  }
  const handleSelectAll = () => {
    if (folderLinks.length === selectedLinks.length) setSelectedLinks([])
    else setSelectedLinks(folderLinks.map(parts => parts.link))
  }

  const handleDownloads = e => {
    e.preventDefault()
    dispatch(getDownloadLink(selectedLinks))
  }
  useEffect(() => {
    if (folderLinks.length) {
      setSelectedLinks([])
    }
  }, [folderLinks])

  return (
    <section className={styles.block}>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
          <h5 className="mt-0 text-success">Get Folder Links</h5>
        </div>
        <div className="d-flex justify-content-around w-100">
          <div style={{ width: '85%' }}>
            <InputBox
              label=""
              name="dlhistory"
              value={folderlink}
              type="text"
              placeholder="Enter folder link"
              onChangeInput={handleChange}
              error={folderLinkError}
            />
          </div>
          <div>
            <Button
              size="md"
              color="primary"
              onClick={getAllLinks}
              disabled={loading || folderLinkError}
            >
              {loading && <Spinner size="sm" color="light" />} Get Links
            </Button>
          </div>
        </div>
        {status === 'online' && !loading && (
          <>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <h5 className="mt-0 mb-0 text-danger">
                Total links: {folderLinks.length} | Selected:{' '}
                {selectedLinks.length}
              </h5>
              <small className="text-danger">
                * Please select from below files which you want to download.{' '}
              </small>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right pb-2">
              <Button
                color="success"
                size="sm"
                onClick={handleDownloads}
                disabled={selectedLinks.length === 0}
              >
                <i className="fas fa-download mr-1" />
                Download{' '}
                {selectedLinks.length === folderLinks.length
                  ? 'All'
                  : 'Selected'}
              </Button>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <Table bordered responsive className={styles.tBody}>
                <thead>
                  <tr>
                    <th>
                      <CheckBox
                        name="selectall"
                        inlineText="Filename"
                        onChangeInput={handleSelectAll}
                        isChecked={selectedLinks.length === folderLinks.length}
                        text="Select all links"
                      />
                    </th>
                    <th className="text-center">Size</th>
                  </tr>
                </thead>
                <tbody>
                  {folderLinks.map(host => (
                    <tr key={host.fname}>
                      <td>
                        <CheckBox
                          name="folderlinks"
                          inlineText={host.fname}
                          onChangeInput={handleSelect(host.link)}
                          isChecked={selectedLinks.includes(host.link)}
                        />
                      </td>
                      <td className="text-center">{host.size}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        {loaded && status !== 'online' && (
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <Alert color="danger">{msg}</Alert>
          </div>
        )}
      </div>
    </section>
  )
}

export default FolderLinks
