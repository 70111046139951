import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, CustomInput } from 'reactstrap'
import { ErrorBoundary } from 'react-error-boundary'
import Cookie from 'js-cookie'

import UserInfo from './UserInfo'
import Generator from './Generator'
import DownloadTable from './DownloadTable'
import BandwidthStats from './BandwidthStats'
import News from './News'
import { USERBANDWIDTH } from '../../data/apis'
import { getData as getUserBandwidth } from '../../reducers/app'
import FolderLinks from './FolderLinks'
import FetchedLinks from './FetchLinks'
import { isPremiumUser } from '../../selectors/user'

import styles from './index.module.scss'
import ErrorFallback from '../ErrorBoundary'
import Adsense from '../Adsense'
import Offers from './Offers'
import Banner from '../Banner'

const showChristmasDeals = process.env.SHOW_CHRISTMAS_DEALS === 'true'

const Dashboard = () => {
  const [showNews, setShowNews] = useState(() => {
    const cookieState = JSON.parse(Cookie.get('show_news') || 'true')
    return cookieState !== undefined ? cookieState : true
  })
  const dispatch = useDispatch()
  const {
    bandwidth: { data, loading, loaded },
  } = useSelector(state => state.app)
  const savedlinks = useSelector(state => state.savedlinks)
  const { links, leeching } = useSelector(state => state.generator)
  const { loading: fetchingLinks, loaded: fetchedLinks } = useSelector(
    ({ app }) => app.fetchedLinks
  )
  const isPremium = useSelector(isPremiumUser)
  const [openStates, setOpenStates] = useState({
    isHistoryOpen: false,
    isFolderOpen: false,
  })

  useEffect(() => {
    dispatch(getUserBandwidth(USERBANDWIDTH))
  }, [])

  if (leeching && showNews) {
    setShowNews(false)
    Cookie.set('show_news', false, { expires: 1 })
  }

  const toggleNews = useCallback(() => {
    setShowNews(!showNews)
    Cookie.set('show_news', !showNews, { expires: 1 })
  })

  const toggleCollapse = type => () => {
    if (type === 'history') {
      setOpenStates({
        isHistoryOpen: !openStates.isHistoryOpen,
        isFolderOpen: false,
      })
    } else if (type === 'folder') {
      setOpenStates({
        isHistoryOpen: false,
        isFolderOpen: !openStates.isFolderOpen,
      })
    } else if (type === 'reset') {
      setOpenStates({
        isFolderOpen: false,
        isHistoryOpen: false,
      })
    }
  }

  return (
    <section className={styles.dashboard}>
      <div className="container-fluid">
        <div className="row">
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              localStorage.clear()
              window.location.reload()
            }}
            resetKeys={[showNews, openStates]}
          >
            <div className="col-lg-8 col-md-8 col-sm-8 col-12">
              <div className="text-right pb-1">
                <CustomInput
                  type="switch"
                  id="news"
                  name="newstoggle"
                  checked={showNews}
                  onChange={toggleNews}
                  label={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Badge color={!showNews ? 'warning' : 'danger'}>
                      {showNews ? 'Hide' : 'Show'} News
                    </Badge>
                  }
                />
              </div>
              <News isOpen={showNews} />
              {showChristmasDeals && (
                <section className={`${styles.block} ${styles.offerContainer}`}>
                  <Offers isPremium={isPremium} />
                </section>
              )}
              {!isPremium && <Adsense slot="6197577535" format="fixed" />}
              <Banner />
              <Generator
                leeching={leeching}
                savedlinks={savedlinks}
                toggleCollapse={toggleCollapse}
                openStates={openStates}
              />
              {links && links.length > 0 && (
                <DownloadTable leeching={leeching} />
              )}
              {!isPremium && <Adsense slot="6222161092" format="responsive" />}
              {openStates.isFolderOpen && isPremium && !leeching && (
                <FolderLinks />
              )}
              {(fetchingLinks || fetchedLinks) && <FetchedLinks />}
              {/* <FetchedLinks /> */}
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              {data.bandwidth_info && data.bandwidth_info.length > 0 && (
                <section className={`${styles.block} ${styles.info} p-0`}>
                  <BandwidthStats
                    data={data.bandwidth_info || []}
                    reset_time={data.reset_time}
                    loading={loading}
                    loaded={loaded}
                  />
                </section>
              )}
              <section className={`${styles.block} ${styles.info} p-0`}>
                {!isPremium && (
                  <Adsense slot="1061349667" format="responsive" />
                )}
              </section>
              <section className={`${styles.block} ${styles.info} p-0`}>
                <UserInfo showUsername={false} />
              </section>
            </div>
          </ErrorBoundary>
        </div>
      </div>
    </section>
  )
}

export default Dashboard
