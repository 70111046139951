import React from 'react'
import { Table, Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { useEffect } from 'react'
import { logout } from '../../reducers/login'
import { PREMIUM } from '../../data/constants'

import { loginState } from '../../selectors/login'
import { combinedDetails } from '../../selectors/user'

const UserInfo = ({ tableSettings, showUsername, setUsername }) => {
  const dispatch = useDispatch()
  const {
    email,
    username,
    lastLogin,
    subscription: { is_vip: isVIP, days, bw_limit: bandwidthLimit },
  } = useSelector(combinedDetails)
  const { loggingOut } = useSelector(loginState)
  useEffect(() => {
    setUsername(username)
  }, [username])
  const handleLogout = () => {
    dispatch(logout())
  }
  return (
    <Table {...tableSettings}>
      <tbody>
        <tr>
          <td colSpan="2">
            <i className="fas fa-envelope" />
            {email}
          </td>
        </tr>
        {showUsername && (
          <tr>
            <td>Username </td>
            <td>{username}</td>
          </tr>
        )}
        <tr>
          <td>Status</td>
          <td>
            {isVIP ? (
              <span className="text-danger font-weight-bold">
                <i className="fas fa-crown fa-md" />
                Premium
              </span>
            ) : (
              <span>
                Free{' '}
                <Button
                  size="sm"
                  color="warning"
                  onClick={() => navigate(PREMIUM)}
                >
                  Buy Premium
                </Button>
              </span>
            )}
          </td>
        </tr>
        {isVIP && (
          <tr>
            <td>Premium </td>
            <td>{days} Days Left</td>
          </tr>
        )}
        <tr>
          <td>Bandwith Limit</td>
          <td>
            {bandwidthLimit} / day
            {/* {bandwidth} / {bw_limit} */}
          </td>
        </tr>
        {lastLogin !== '' && (
          <tr>
            <td>Last login</td>
            <td>{`${lastLogin} IST`}</td>
          </tr>
        )}
        <tr className="text-right">
          <td colSpan="2">
            <Button
              color="danger"
              onClick={handleLogout}
              size="sm"
              disabled={loggingOut}
            >
              <i
                className={
                  loggingOut ? 'fas fa-redo-alt fa-spin' : 'fas fa-sign-out-alt'
                }
              />
              Logout
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

UserInfo.defaultProps = {
  showUsername: false,
  setUsername: () => {},
}

UserInfo.propTypes = {
  showUsername: PropTypes.bool,
  tableSettings: PropTypes.object.isRequired,
  setUsername: PropTypes.func,
}

export default UserInfo
