import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import Videojs from './Video'

const StreamOptions = ({ filename, dlink }) => {
  const [isOpen, setOpen] = useState(false)

  const isStreamable = /\.mp4$/i.test(filename)
  const isVlcStreamable = /\.(mkv|wmv|mp4|avi|mp3|flv|webm)$/i.test(filename)

  const handlePopup = () => {
    setOpen(!isOpen)
  }

  const handleVLCDownload = (name, link) => () => {
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(
      new Blob(
        [
          `<?xml version="1.0" encoding="UTF-8"?>
            <playlist version="1" xmlns="http://xspf.org/ns/0/">
              <trackList>
              <track>
                <location>${link}</location>
              </track>
              </trackList>
            </playlist>`,
        ],
        { 'Content-Type': 'application/force-download' }
      )
    )
    a.download = `${name}.xspf`
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    // Remove anchor from body
    document.body.removeChild(a)
  }
  return (
    <>
      {isStreamable && (
        <Button
          title="Stream link online"
          color="success"
          size="sm"
          className="mr-1"
          onClick={handlePopup}
        >
          <i className="fa fa-play mr-1" aria-hidden="true" />
          Stream
        </Button>
      )}
      {isVlcStreamable && (
        <Button
          title="Stream link via VLC Player"
          color="warning"
          size="sm"
          className="mr-1"
          onClick={handleVLCDownload(filename, dlink)}
        >
          <i className="far fa-file-video mr-1" />
          VLC
        </Button>
      )}
      {isOpen && (
        <Videojs
          isOpen={isOpen}
          handlePopup={handlePopup}
          filename={filename}
          dlink={dlink}
        />
      )}
    </>
  )
}

StreamOptions.defaultProps = {
  filename: '',
  dlink: '',
}

StreamOptions.propTypes = {
  filename: PropTypes.string,
  dlink: PropTypes.string,
}

export default StreamOptions
