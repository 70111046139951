import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Collapse, Table, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import InputBox from '../Form/InputBox'
import styles from './index.module.scss'
import HistoryTable from './HistoryTable'

import { DLHISTORY } from '../../data/apis'
import { getData as loadDlHistory } from '../../reducers/app'
import { PROFILE, PREMIUM } from '../../data/constants'
import { isPremiumUser } from '../../selectors/user'

const DownloadHistory = ({ isOpen }) => {
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const {
    dlhistory: {
      loading,
      loaded,
      data: { isActive, results },
    },
  } = useSelector(state => state.app)

  const isPremium = useSelector(isPremiumUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen && isPremium) {
      dispatch(loadDlHistory(DLHISTORY))
    }
  }, [isOpen, isPremium])

  const handleSearch = e => {
    const {
      target: { value },
    } = e
    setQuery(value)
    if (results && results.length) {
      const applyFilter = results.filter(result => {
        const regex = new RegExp(value, 'gi')
        return result.link.match(regex) || result.filename.match(regex)
      })
      setSearchResults({
        searchResults: applyFilter,
      })
    }
  }
  return (
    <Collapse isOpen={isOpen} className={styles.dl_history}>
      <div className="mt-3 h-25">
        {loading && (
          <div className="text-center">
            <Spinner type="grow" color="primary" />
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="success" />
            <Spinner type="grow" color="danger" />
          </div>
        )}
        {/*eslint-disable */}
        {!isPremium && (
          <h6 className="text-center">
            <i className="fas fa-info-circle" />
            Download history available only for Premium users.{' '}
            <div>
              <Link to={PREMIUM}>Buy Premium</Link>
            </div>
          </h6>
        )}
        {loaded && isPremium && !isActive && (
          <h6 className="text-center">
            <i className="fas fa-info-circle" />
            Please enable download history option in your{' '}
            <Link to={`${PROFILE}#settings`}>profile settings!</Link>
          </h6>
        )}
        {loaded && isPremium && isActive && (
          <>
            <div className={styles.dlHeading}>
              <h5 className={`${styles.h5} btn-warning text-center`}>
                {query === '' ? (
                  <span>Download History</span>
                ) : (
                  `${searchResults.length} results found`
                )}
              </h5>
              <InputBox
                label=""
                name="dlhistory"
                value={query}
                type="text"
                placeholder="Search for your links or filename"
                onChangeInput={handleSearch}
                error={''}
              />
              {results.length ? (
                <h5 className={`${styles.tlinks} text-danger`}>
                  Total: {results.length} link(s)
                </h5>
              ) : (
                ''
              )}
            </div>
            <Table striped className={styles.tBody}>
              <thead>
                <tr>
                  <th>Original Link</th>
                  <th>File name</th>
                  <th>Stream/Download</th>
                </tr>
              </thead>
              <tbody>
                {results && !results.length && (
                  <tr>
                    <td className="text-center text-danger">
                      No downloads yet! <br />
                      Each link you generate is stored for 24 hours from the
                      time of generation.
                    </td>
                  </tr>
                )}
                {results &&
                  results.length > 0 &&
                  (query === ''
                    ? results
                    : searchResults
                  ).map((item, index) => (
                    <HistoryTable
                      filename={item.filename}
                      link={item.link}
                      dlink={item.dlink}
                      key={String(index)}
                    />
                  ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
    </Collapse>
  )
}

DownloadHistory.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default DownloadHistory
