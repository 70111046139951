/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Button } from 'reactstrap'
import StreamOptions from './StreamOptions'
import { trimText } from '../../helpers'
import styles from './index.module.scss'

const FileInfo = ({
  filename,
  filesize,
  link,
  dlink,
  serverId,
  isRetried,
  isPremium,
}) => (
  <div className="row">
    <div className="col-6 p-0">
      {trimText(link, 61, 2)}
      <br />
      <Badge color="warning" className={styles.fileInfo}>
        {trimText(filename, 30, 10)}
      </Badge>
      <Badge color="danger" className={styles.fileInfo}>
        {filesize}
      </Badge>
      <Badge color="dark" pill>
        <b>Server: </b>
        {serverId + 1}
      </Badge>
    </div>
    <div className="col-sm-6 mt-2 p-0 text-right">
      {isPremium && <StreamOptions dlink={dlink} filename={filename} />}
      <a
        href={dlink}
        title="Download link"
        {...(!isPremium
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : { download: true })}
      >
        <Button color={`${isRetried ? 'success' : 'primary'}`} size="sm">
          <i className="fa fa-download mr-1" aria-hidden="true" />
          Download
        </Button>
      </a>
    </div>
  </div>
)

FileInfo.defaultProps = {
  filename: '',
  filesize: '',
  link: '',
  dlink: '',
  serverId: 0,
  isRetried: false,
  isPremium: false,
}

FileInfo.propTypes = {
  filename: PropTypes.string,
  filesize: PropTypes.string,
  link: PropTypes.string,
  dlink: PropTypes.string,
  serverId: PropTypes.number,
  isRetried: PropTypes.bool,
  isPremium: PropTypes.bool,
}

export default FileInfo
