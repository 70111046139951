import React, { useState } from 'react'
import { Alert, Collapse, Button } from 'reactstrap'

export default function Banner() {
  const [isOpen, setIsOpen] = useState(false)
  function handleClick() {
    setIsOpen(!isOpen)
  }
  return (
    <div className="alert-banner-container">
      <Alert color="primary">
        <div className="d-flex justify-content-between align-items-center">
          <strong className="fn-15">
            <i className="far fa-bell fa-1x"></i>
            Now you can Upgrade to Premium with your Credit/Debit Cards
            directly.
          </strong>
          <Button color="primary" size="sm" outline onClick={handleClick}>
            <i className="fas fa-chevron-circle-down"></i>
          </Button>
        </div>
        <Collapse isOpen={isOpen}>
          <div>
            <ul className="banner-li">
              <li>Pay with your Credit or Debit Card directly.</li>
              <li>Instant Premium Activation after payment.</li>
              <li>Securely pay with your cards.</li>
            </ul>
          </div>
        </Collapse>
      </Alert>
    </div>
  )
}
