import React from 'react'
import { Spinner } from 'reactstrap'

const Loading = () => {
  return (
    <div className="app-loading">
      <Spinner type="grow" color="primary" />
      <Spinner type="grow" color="secondary" />
      <Spinner type="grow" color="success" />
      <Spinner type="grow" color="danger" />
    </div>
  )
}

export default Loading
