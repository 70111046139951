import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { useLocation } from '@reach/router'

import { isUserLoggedIn } from '../../selectors/login'
import { LOGIN } from '../../data/constants'
import Loading from '../Loader'

const isBrowser = typeof window !== `undefined`

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const isLoggedIn = useSelector(isUserLoggedIn)
  const isSynced = useSelector(({ app }) => app.app.sync)
  const newPath =
    location.pathname !== '/' ? location.pathname.replace(/\/$/, '') : '/'
  if (!isSynced) {
    return <Loading />
  }
  if (!isLoggedIn && newPath !== LOGIN) {
    // If we’re not logged in, redirect to the home page.
    // eslint-disable-next-line no-unused-expressions
    isBrowser && navigate(`${LOGIN}/?redirect=${newPath}${location.search}`)
    return null
  }
  return <Component {...rest} location={location} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
}

PrivateRoute.defaultProps = {
  location: {},
}

export default PrivateRoute
