import React from 'react'
import PropTypes from 'prop-types'
import Button from 'reactstrap/lib/Button'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      role="alert"
      className="d-flex justify-content-center flex-column align-items-center m-auto"
    >
      <p className="font-weight-bold">
        <i className="fas fa-heart-broken mr-2"></i>
        Something went wrong
      </p>
      <pre style={{ color: 'red' }} className="col-md-auto">
        {error && error.message}
      </pre>
      <Button size="sm" color="danger" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
}

export default ErrorFallback
