import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Spinner, Badge, Collapse } from 'reactstrap'

import socket from '../../socket'

import styles from './index.module.scss'

const getFont = type => {
  switch (true) {
    case type === 'fix':
      return 'fas fa-tools text-danger'
    case type === 'new':
      return 'fas fa-plus-circle text-primary'
    case type === 'info':
      return 'fas fa-info-circle text-info'
    case type === 'other':
      return 'fas fa-asterisk text-success'
    case type === 'hold':
      return 'fas fa-cog fa-spin text-warning'
    default:
      return 'far fa-arrow-alt-circle-right text-warning'
  }
}

const News = ({ isOpen }) => {
  const isUnmounting = useRef(false)
  const [state, setState] = useState({
    data: [],
    loading: true,
  })
  useEffect(() => {
    function handleResponse({ data }) {
      if (!isUnmounting.current) {
        setState({
          data,
          loading: false,
        })
        isUnmounting.current = false
      }
    }
    socket.emit('news')
    socket.on('news', handleResponse)
    return () => {
      socket.off('news', handleResponse)
      isUnmounting.current = true
    }
  }, [isUnmounting, isOpen])

  const { loading, data } = state
  return (
    <Collapse isOpen={isOpen}>
      <section className={styles.block}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 pb-0">
            <h5 className="mt-1">
              <i className="fa fa-bullhorn fa-md" />
              Latest News
            </h5>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {!loading && !data.length && (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <i className="fas fa-exclamation-circle fa-2x text-danger" />
                  <div>Oops! Something went wrong..</div>
                </div>
              )}
              {loading && data.length === 0 && (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <Spinner type="grow" color="primary" />
                  <Spinner type="grow" color="secondary" />
                  <Spinner type="grow" color="success" />
                  <Spinner type="grow" color="danger" />
                </div>
              )}
              <ul className="pl-0 pt-2">
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <div key={item.created_at}>
                      <Badge color="light">
                        <i className="far fa-calendar-alt"></i>
                        <small className={styles.createdDate}>
                          {new Date(item.created_at).toDateString({})}
                        </small>
                      </Badge>
                      <li
                        key={String(index)}
                        className={`pb-2 pt-1 ${styles.newsLi}`}
                      >
                        <i className={getFont(item.type)} title={item.type} />
                        {item.description}{' '}
                      </li>
                    </div>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Collapse>
  )
}

News.defaultProps = {
  isOpen: true,
}

News.propTypes = {
  isOpen: PropTypes.bool,
}

export default News
