import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Progress, Button, Collapse, Spinner } from 'reactstrap'
import { useDispatch } from 'react-redux'

import Timer from './Timer'
import { resetState } from '../../reducers/app'
import { bytesToSize, toCamelCase } from '../../helpers'
import styles from './index.module.scss'

const setColor = val => {
  switch (true) {
    case val < 50:
      return 'primary'
    case val > 50:
      return 'danger'
    default:
      return 'success'
  }
}

const BandwidthStats = ({ data, reset_time: resetTime, loading, loaded }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const toggle = () => setIsOpen(!isOpen)
  const firstSlice = data.slice(0, 4)
  const restSlices = data.slice(4)

  const handleReset = () => {
    dispatch(resetState('bandwidth'))
  }
  return (
    <div className="row">
      <div
        className={`${styles.status} col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-between`}
      >
        <h5 className="mt-0">
          <i className="fas fa-tachometer-alt"></i>Bandwidth
        </h5>
        {resetTime && (
          <div className="text-warning font-weight-bold reset-timer">
            <Timer tm={resetTime} timeEndCallback={handleReset}>
              {/* {time => <span>{time !== '' ? `Next Reset: ${time}` : ''}</span>} */}
              {() => {}}
            </Timer>
          </div>
        )}
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
        <div className="p-3">
          <div className="mx-1">
            {loading && !loaded && (
              <div className="text-center">
                <Spinner color="danger" />
              </div>
            )}
            {firstSlice.map(host => (
              <div key={String(host.id)} className="mb-3">
                <div className="d-flex justify-content-between my-1">
                  <div>
                    <i
                      className={`hosts_${host.host.replace(/\./g, '_')} mr-1`}
                    ></i>
                    {toCamelCase(host.host)}
                  </div>
                  <div className="text-right fontsize-12">
                    {bytesToSize(host.bw_used)}/{bytesToSize(host.max_bw)}
                  </div>
                </div>
                <Progress
                  color={setColor(Math.round(host.bw_used / host.max_bw) * 100)}
                  className={styles.progress}
                  value={host.bw_used}
                  max={host.max_bw}
                  striped
                  animated
                />
              </div>
            ))}
            <Collapse isOpen={isOpen}>
              {restSlices.map(host => (
                <div key={String(host.id)} className="mb-3">
                  <div className="d-flex justify-content-between my-1">
                    <div>
                      <i
                        className={`hosts_${host.host.replace(
                          /\./g,
                          '_'
                        )} mr-1`}
                      ></i>
                      {toCamelCase(host.host)}
                    </div>
                    <div className="text-right fontsize-12">
                      {bytesToSize(host.bw_used)}/{bytesToSize(host.max_bw)}
                    </div>
                  </div>
                  <Progress
                    color={setColor(
                      Math.round(host.bw_used / host.max_bw) * 100
                    )}
                    className={styles.progress}
                    value={host.bw_used}
                    max={host.max_bw}
                    striped
                    animated
                  />
                </div>
              ))}
            </Collapse>
          </div>
          {restSlices && restSlices.length > 0 && (
            <div className="text-center">
              <Button size="sm" onClick={toggle}>
                <i
                  className={`fas fa-arrow-circle-${isOpen ? 'up' : 'down'}`}
                />
                {isOpen ? 'Less' : 'More'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

BandwidthStats.defaultProps = {
  data: [],
  reset_time: 0,
  loading: false,
  loaded: false,
}

BandwidthStats.propTypes = {
  data: PropTypes.array,
  reset_time: PropTypes.number,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
}

export default React.memo(BandwidthStats)
